import React from 'react'
import Layout from '../components/layout'
import { graphql } from 'gatsby'
import SanityContentRenderer from '../components/sanity-content-renderer'
import { ContainerSm } from '../components/container'
import SEO from '../components/seo'

function ImprintPage({ data }) {
  const contentBlock = data.page.edges[0].node._rawContentBlocks[0]
  return (
    <Layout>
      <SEO title="Über uns" />
      <ContainerSm>
        <SanityContentRenderer blocks={contentBlock.content} />
      </ContainerSm>
    </Layout>
  )
}

export const query = graphql`
  query ImprintPageQuery {
    page: allSanityPage(filter: { path: { eq: "/imprint" } }) {
      edges {
        node {
          _rawContentBlocks
        }
      }
    }
  }
`

export default ImprintPage
